// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticating, setAuthenticating] = useState(true);

  // Initialize state from localStorage or default to null
  const [token, setToken] = useState(() => localStorage.getItem('token') || null);
  const [userEmail, setUserEmail] = useState(() => localStorage.getItem('userEmail') || null);
  const [userId, setUserId] = useState(() => localStorage.getItem('userId') || null);

  // isAuthenticated is derived from the presence of token, userId, and userEmail
  const [isAuthenticated, setAuthenticated] = useState(() => {
    return token && userId && userEmail ? true : false;
  });

  // Update isAuthenticated whenever token, userId, or userEmail changes
  useEffect(() => {
    setAuthenticated(token && userId && userEmail ? true : false);
  }, [token, userId, userEmail]);

  // Persist token to localStorage
  useEffect(() => {
    // console.log("updating token in authtext to", token);
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  }, [token]);

  // Persist userEmail to localStorage
  useEffect(() => {
    // console.log("updating userEmail in authtext to", userEmail);
    if (userEmail) {
      localStorage.setItem('userEmail', userEmail);
    } else {
      localStorage.removeItem('userEmail');
    }
  }, [userEmail]);

  // Persist userId to localStorage
  useEffect(() => {
    // console.log("updating userId in authtext to", userId);
    if (userId) {
      localStorage.setItem('userId', userId);
    } else {
      localStorage.removeItem('userId');
    }
  }, [userId]);

  // Context provider values
  const value = {
    isAuthenticated,
    setAuthenticated,
    isAuthenticating,
    setAuthenticating,
    userId,
    setUserId,
    userEmail,
    setUserEmail,
    token,
    setToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

