import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function Signup({ setAuthenticated }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); // Get the navigate function

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
      return re.test(email);
  };

  const validatePassword = (password) => {
    const hasLetters = /[a-zA-Z]/.test(password);
    const hasNumbers = /\d/.test(password);
    return password.length > 3 && hasLetters && hasNumbers;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
        

    if (!validateEmail(email)) {
      setErrorMessage('Invalid email format.');
      return;
    }

    if (!validatePassword(password)) {
      setErrorMessage('Password must be more than 3 characters and contain both letters and numbers.');
      return;
    }

    // Proceed with signup process
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        credentials: 'include', // This is important
      });

      const data = await response.json(); // Parse the JSON response

      if (response.ok) {
        // Handle successful signup
        setAuthenticated(true);
        navigate('/');
      } else {
        setErrorMessage(data.error || 'Signup failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during signup:', error);

      setErrorMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input 
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input 
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button type="submit">Sign Up</button>
        {errorMessage && <p>{errorMessage}</p>}
      </form>
    </div>
  );
};

export default Signup;
