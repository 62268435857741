// Chat.js
import React, { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from './AuthContext';
import './Chat.css';

function Chat({ onClose, chatEmail }) {
  // function Chat({ onClose, token, chatEmail }) {
  const { isAuthenticated, userId, userEmail, token } = useContext(AuthContext); // Access userId
  const [isConnectedChat, setIsConnectedChat] = useState(false); // Track connection status
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [ws, setWs] = useState(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };

  // Scroll to the bottom whenever messages are updated
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!token) {
      console.error('No token found in chat');
      return;
    } else { 
      // localStorage.setItem('chatToken', token); // chat token now set here on initial load.
    }

    // Establish WebSocket connection
    const socket = new WebSocket(`wss://pvpmvp.com:3002/?token=${token}`);
    
    setWs(socket);
  
    socket.onopen = () => {
      
      setIsConnectedChat(true); // Reset connection status on close

      // alert(`Connected to chat server using token: ${token} email:${userEmail} id:${userId}`);
    };
  
    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
  
      if (message.type === 'chatHistory') {
        setMessages(message.data);
      } else if (message.type === 'newMessage') {
        setMessages((prevMessages) => [...prevMessages, message.data]);
      }
    };
  
    socket.onclose = (event) => {
      
      setWs(null);
      setIsConnectedChat(false); // Reset connection status on close

      if (event.code === 4400) { // New chat socket connection made
        // alert(`New Chat Connection Established`);
        // setAuthenticated(false); // Update authentication state
        // setUserId(null);
        // setUserEmail(null);
        // setToken(null);
        // localStorage.clear();
        // sessionStorage.clear();
        // navigate('/'); // Redirect to the login page
      }

    };
  
    socket.onerror = (error) => {
      console.error('Chat WebSocket error:', error);
    };
  
    return () => {
      socket.close();
    };
  }, [token]); // Directly use the token prop here

  const sendMessage = () => {
    if (ws && input.trim() !== '') {
      const message = {
        type: 'newMessage',
        content: input.trim(),
      };
      ws.send(JSON.stringify(message));
      setInput('');
    }
  };

  return (
    <div className="chat-modal">
        <button
          onClick={() => console.log(token ? "User is online" : "User is offline")}
          style={{
            color: token ? '#00ff15' : '#ff0000', // Green if online, Red if offline
            background: '#ffffff00',
            border: '#ffffff00',
            transition: 'background-color 0.3s ease', // Smooth color transition
            textShadow: '1px 1px 2px #000000;' // Adjusted text-shadow
          }}>
          {token ? 'Online' : 'Offline'}
        </button>
        <div className="chat-header">
            <h2>{chatEmail ? "Global Chat" : "Connecting to chat..."}</h2>
        </div>
        <button className="chat-close-button" onClick={onClose}>×</button>
        <div className="chat-messages">
          {messages.map((msg, index) => {
            const isMe = msg.playerId === chatEmail; // Assuming chatEmail is the logged-in user's email
            return (
              <div key={index} className={`chat-message ${isMe ? 'from-me' : 'from-other'}`}>
                <span className="chat-timestamp">
                  {new Date(msg.timestamp).toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                    month: '2-digit',
                    day: '2-digit',
                    year: '2-digit'
                  })}
                </span>
                <span className="chat-playerId">{msg.playerId}</span>
                <p className="chat-content">{msg.content}</p>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </div>
        <div className="chat-input">
          <input
            type="text"
            value={input}
            placeholder="Type a message..."
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') sendMessage();
            }}
          />
         <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
}

export default Chat;
// export default React.memo(Chat);


