import './LandingPage.css'; // Import the CSS file
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom'; // Import Redirect here
import Login from './Login';
import Signup from './Signup';
import SpaceDiv from './SpaceDiv';
import { AuthContext } from './AuthContext'; // Import AuthContext

    // remove signup unless needed for new accounts
    // {/* Pass setAuth as a prop */}

function LandingPage() {
  const { isAuthenticated, setAuthenticated } = useContext(AuthContext);

  // console.log("isAuthenticated in landing", isAuthenticated);
  if (isAuthenticated) {
    // Redirect to the main page if already authenticated
    return <Navigate to="/main" />; // Use React Router's Redirect
  }
 
  return (
    <div className="login-container">
      <div className="welcome-title">Welcome to Auth</div>
      <Signup
        style={{
          position: 'absolute',
          color: 'black', // White text for better contrast
          borderRadius: '5px',
          height: '5%', // Set height to 5% of the container
          width: '70%', // Set height to 5% of the container
          fontWeight: 'bold', // Bold font weight
          fontSize: '1.25rem', // 25% larger font size (adjust as needed)
          textAlign: 'center', // Align text to the left
        }}
        setAuthenticated={setAuthenticated} 
      /> 
      <div className="separation"></div> {/* Separation */}
      <SpaceDiv> style={{
                        position: 'absolute'
                        }}
      </SpaceDiv>
    <Login
      style={{
        position: 'absolute',
        color: 'black', // White text for better contrast
        borderRadius: '5px',
        height: '5%', // Set height to 5% of the container
        width: '70%', // Set height to 5% of the container
        fontWeight: 'bold', // Bold font weight
        fontSize: '1.25rem', // 25% larger font size (adjust as needed)
        textAlign: 'center', // Align text to the left
      }}
        setAuthenticated={setAuthenticated} /> {/* Pass setAuth as a prop */}
    </div>
  );
}

export default LandingPage;
