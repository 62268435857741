import { AuthProvider } from './AuthContext';
import LandingPage from './LandingPage';
import MainPage from './MainPage'; // Your main page component
import GamePage from './GamePage'; // Import the GamePage component
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/main" element={<MainPage />} />
          <Route path="/game/:gameId" element={<GamePage />} /> {/* Add this route for the GamePage */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;





