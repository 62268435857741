import React, { useContext } from 'react';
import { AuthContext } from './AuthContext'; // Import your AuthContext
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function Logout() {
  const { setAuthenticated, setUserId, setUserEmail, setToken } = useContext(AuthContext); // Assuming setAuthenticated updates your authentication state
  const navigate = useNavigate(); // Get the navigate function

  const handleLogout = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
      method: 'POST', // Change from 'GET' to 'POST'
      headers: {
        'Content-Type': 'application/json', // Ensure correct content type for POST
      },
      credentials: 'include', // Include cookies with request (if needed for session handling)
    });

    if (response.ok) {
      localStorage.clear();
      sessionStorage.clear();
      setAuthenticated(false); // Update authentication state
      setUserId(null);
      setUserEmail(null);
      setToken(null);
      navigate('/'); // Redirect to the login page
    }
  } catch (error) {
    console.error('Error during logout:', error);
  }
};

  return (
      <div>
        <button 
          style={{
            width: '125px',           
            display: 'block',        
            marginLeft: 'auto',     
            marginRight: 'auto',    
            backgroundColor: 'lightgray', // Initial background color
            color: 'white',
            padding: '5px 10px',
            border: '2px solid #000',
            borderRadius: '10px',
            fontSize: '1rem',
            transition: 'background-color 0.3s ease', // Smooth color transition
          }}
          onClick={handleLogout}
          onMouseEnter={(e) => e.target.style.backgroundColor = 'darkred'} // Change color on hover
          onMouseLeave={(e) => e.target.style.backgroundColor = 'lightgray'} // Restore color on mouse leave
        > Logout </button>
      </div>
  );
}

export default Logout;
